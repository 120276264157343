import * as constants from "./constants";
import TruckyAPIClient from "../services/TruckyAPIClient";
import Cookies from "universal-cookie";

var client = new TruckyAPIClient();

export function checkAuth() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      const cookies = new Cookies(null, { path: "/" });

      if (cookies.get("trucky-auth-token")) {
        fetch("https://e.truckyapp.com/api/v1/user/me", {
          headers: {
            "User-Agent": "Trucky",
            "x-access-token": cookies.get("trucky-auth-token"),
          },
        }).then(async (response) => {
          const json = await response.json();

          if (
            json.additional_roles &&
            json.additional_roles.length > 0 &&
            (json.additional_roles.includes("developer") ||
              json.additional_roles.includes("service_team") ||
              json.additional_roles.includes("ambassador"))
          ) {
            dispatch({
              type: constants.CHECK_AUTH,
              user: json,
              authenticated: true,
            });

            resolve(true);
          } else resolve(false);
        });
      } else resolve(false);
    });
  };
}

export function logout() {
  return function (dispatch) {
    cookie.remove("trucky-admin-auth");
    document.location.href = "/logout";
  };
}

export function getMapServers() {
  return function (dispatch) {
    return client.map_servers().then((response) => {
      dispatch({
        type: constants.GET_MAP_SERVERS,
        mapServers: response.response,
      });
    });
  };
}

export const globalMapDispatchToProps = (dispatch) => {
  return {
    checkAuth: () => dispatch(checkAuth()),
    getMapServers: () => dispatch(getMapServers()),
    logout: () => dispatch(logout()),
  };
};
